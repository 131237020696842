<template>
	<div class="bg-F7F7F7">
		<div class="bg-white swiper paddt54">
			<div id="certify">
				<div class="swiper-container">
					<div class="swiper-wrapper" ref="swiperBox">
						<div class="swiper-slide" :data-swiper-autoplay="1500" v-for="(item, i) in items" :key="i"
							@click="godetails(item)">
							<img :src="item.pc_image" alt="" />
							<div class="paddlr15">
								<div class="paddt21 co-333333 fz20 font-blod">{{item.title}}</div>
								<div class="mart16 co-999999 fz14 two-point desc">
									{{item.desc}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="paddt22">
			<div class="fz40 co-333333 w1200 margin-center">超级名师</div>

			<div class="paddt42 paddb40 w1200 margin-center flex-column">
				<div class="align-items-center">
					<span class="fz20 co-333333 font-blod name display-block">国家</span>
					<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.country" :key="i"
						:class="[titleIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']"
						@click="titleChoose(1, i)">{{item.name}}</div>
				</div>
				<div class="align-items-center mart20">
					<span class="fz20 co-333333 font-blod name display-block">学位</span>
					<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.degree" :key="i"
						:class="[titleListTwoIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']"
						@click="titleChoose(2, i)">{{item.name}}</div>
				</div>
				<div class="align-items-center mart20">
					<span class="fz20 co-333333 font-blod name display-block">专业</span>
					<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.profess" :key="i"
						:class="[titleListThreeIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']"
						@click="titleChoose(3, i)">{{item.name}}</div>
				</div>
				<div class="align-items-center mart20">
					<span class="fz20 co-333333 font-blod name display-block">所在地区</span>
					<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.area " :key="i"
						@click="titleChoose(4, i)" :class="[titleListFourIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']">			
							{{item.name}}
					</div>
				</div>
			</div>

			<div class="mart20">
				<div class="w1200 margin-center  ">
					<teacherlist :list='list'></teacherlist>
					<loadMore :state="state" loading_icon></loadMore>
				</div>
			</div>
				<div v-loading.fullscreen.lock="fullscreenLoading"></div>

		</div>
	</div>
</template>

<script>
	import Swiper from "swiper";
	import 'swiper/swiper.less';
	// import { swiperFuction } from '../assets/js/swiperModel.js';
	import {
		ref,
		onMounted,
		nextTick
	} from 'vue';
	import $ from 'jquery';

	import SwiperCore, {
		Autoplay
	} from 'swiper/core';
	SwiperCore.use([Autoplay]);

	export default {
		name: 'Teacher',
		setup() {
			const swiperBox = ref(null);

			onMounted(() => {
				nextTick(() => {
					let mySwiper = new Swiper('#certify .swiper-container', {
						watchSlidesProgress: true,
						slidesPerView: 'auto',
						centeredSlides: true,
						loop: false,
						initialSlide: 1,
						loopedSlides: 4,
						autoplay: {
							delay: 1000,
							disableOnInteraction: true,
						},
						observer: true, //修改swiper自己或子元素时，自动初始化swiper
						observeParents: true, //修改swiper的父元素时，自动初始化swiper
						on: {
							progress: (progress) => {
								for (let i = 0; i < swiperBox.value.children.length; i++) {
									var slide = swiperBox.value.children[i];
									var slideProgress = swiperBox.value.children[i].progress;
									let modify = 1;
									if (Math.abs(slideProgress) > 1) {
										modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
									}
									let translate = slideProgress * modify * 200 + 'px';
									let scale = 1 - Math.abs(slideProgress) / 5;
									let zIndex = 999 - Math.abs(Math.round(10 *
										slideProgress));
									$(slide).css('transform', 'translateX(' + translate +
										') scale(' + scale + ')');
									$(slide).css('zIndex', zIndex);
									$(slide).css('opacity', 1);
									if (Math.abs(slideProgress) > 3) {
										$(slide).css('opacity', 0);
									}
								}
							},
							setTransition: (transition) => {
								for (var i = 0; i < swiperBox.value.children.length; i++) {
									var slide = swiperBox.value.children[i]
									// console.log(transition)
									$(slide).css('transition', 'all 0s');
								}
							},
							transitionStart: () => {
								for (var i = 0; i < swiperBox.value.children.length; i++) {
									var slide = swiperBox.value.children[i]
									$(slide).css('transition', 'all 0.3s');
								}
							},
						}
					})
				})
			});
			return {
				swiperBox,
			};
		},
		data() {
			return {
				// titleList: ['全部', '中国', '加拿大', '美国'], // 筛选标题
				// titleListTwo: ['全部', '硕士', '研究生', '博士生', '硕士', '研究生', '博士生'], // 筛选标题
				// titleListThree: ['全部', '建筑设计', '景观设计', '商业区', '建筑设计', '景观设计', '商业区'], // 筛选标题
				// titleListFour: ['全部', '纽约', '加州', '北京', '纽约', '加州', '北京'], // 筛选标题
				titleIndex: 0, // 筛选标题选中
				titleListTwoIndex: 0, //  筛选标题选中
				titleListThreeIndex: 0, //  筛选标题选中
				titleListFourIndex: 0, //  筛选标题选中
				items: [],
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dictTotal: 0,
				size: 16,
				dataObj: {
					country_id: 0, //国家
					profess_id: 0, //专业
					degree_id: 0,
					city_id: 0
				},
				pagesinfo: {},
				fullscreenLoading: true,
			}
		},
		created() {

		},
		mounted() {
			this.$nextTick(() => {
				this.$req({
					method: 'get',
					data: {
						type: 'teacher'
					},
					url: '/api/maple/carousel',
					success: res => {
						this.items = res
					},
					fail: error => {

					}
				});
				this.$req({
					method: 'get',
					data: {
						type: 'inspiration'
					},
					url: '/api/teacher/search',
					success: res => {
						
						this.pagesinfo = res;
						this.pagesinfo.country.unshift({
							name: '全部',
							id: 0
						})
						this.pagesinfo.degree.unshift({
							name: '全部',
							id: 0
						})
						this.pagesinfo.profess.unshift({
							name: '全部',
							id: 0
						})
						this.pagesinfo.area.unshift({
							name: '全部',
							id: 0
						})
					},
					fail: error => {

					}
				});
				this.getlist();
			});
		},
		methods: {
			getlist() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 100;
				this.$req({
					method: 'get',
					data: {
						...this.dataObj,
						page: page,
						size: size,
					},
					url: '/api/teacher',
					success: res => {
						// this.fullscreenLoading = false;
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list = this.list.concat(list);
						}
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
						this.fullscreenLoading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
						this.fullscreenLoading = false;
					}
				});
			},
			godetails(item) {
				console.log(item)
				// 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
				let data = item.link_type;
				let id = item.link_id
				let type = '';
				let url = '/Detail';
				console.log(data)
				if (data == 'source') {
					type = 1
				} else if (data == 'picture') {
					type = 2
				} else if (data == 'competition') {
					type = 3
				} else if (data == 'school') {
					type = 4
				} else if (data == 'inspiration') {
					type = 5
				} else if (data == 'teacher') {
					this.$router.push({
						path: '/TeacherDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				} else if (data == 'agency') {
					this.$router.push({
						path: '/SincerityDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				} else if (data == 'course') {
					this.$router.push({
						path: '/ClassDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}

				this.$router.push({
					path: url,
					query: {
						type: type,
						id: id
					}
				})
			},
			// 筛选选中
			titleChoose(type, i) {
				if (type == 1) {
					this.titleIndex = i;
					this.dataObj.country_id = this.pagesinfo.country[i].id
				} else if (type == 2) {
					this.titleListTwoIndex = i
					this.dataObj.degree_id = this.pagesinfo.degree[i].id
				} else if (type == 3) {
					this.titleListThreeIndex = i
					this.dataObj.profess_id = this.pagesinfo.profess[i].id
				} else if (type == 4) {
					this.titleListFourIndex = i
					this.dataObj.city_id = this.pagesinfo.area[i].id
				}
				this.list = [];
				this.page = 0;
				this.state = 0;
				this.getlist()
			},
		}
	}
</script>

<style lang="less" scoped>
	.desc {
		height: 52px;
	}

	#certify {
		position: relative;
		width: 1200px;
		margin: 0 auto
	}

	#certify .swiper-container {
		padding-bottom: 50px;
	}

	#certify .swiper-slide {
		width: 490px;
		height: 546px;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		background: #fff;
	}

	#certify .swiper-slide img {
		width: 490px;
		height: 378px;
		border-radius: 12px 12px 0px 0px;
		display: block;
	}

	#certify .swiper-slide p {
		line-height: 98px;
		padding-top: 0;
		text-align: center;
		color: #636363;
		font-size: 1.1em;
		margin: 0;
	}

	#certify .swiper-pagination {
		width: 100%;
		bottom: 20px;
	}

	#certify .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 5px;
		border: 3px solid #fff;
		background-color: #d5d5d5;
		width: 10px;
		height: 10px;
		opacity: 1;
	}

	#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
		border: 3px solid #00aadc;
		background-color: #fff;
	}

	.name {
		width: 80px;
		text-align: justify;
		text-align-last: justify;
	}

	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}

	.item {
		width: 283px;
		border: 1px solid #F7F7F7;

		.item-name {
			padding-left: 15px;
			padding-right: 16px;
		}

		.label-item {
			width: 78px;
			height: 28px;
			background: rgba(50, 177, 108, 0.12);
			border-radius: 4px;
		}

		.label-item-01 {
			width: 78px;
			height: 28px;
			background: rgba(241, 145, 73, 0.12);
			border-radius: 4px;
		}

		.app_06 {
			height: 220px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}

	.item:hover {
		width: 283px;
		border: 1px solid #DCFF03;
		cursor: pointer;
	}
</style>
